.myLoading {
    height: 100vh;
  }
  .form {
    position: relative;
  }
  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  
    background-color: rgba(0, 0, 0, 0.1); 
  }
  .spinner {
    position: absolute;
  top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .testFade {
    color: "#97a3b9";
    margin-top: "10px";
}
  